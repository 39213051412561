<template>
  <div>
    <ErrorCausesTable
      :items="errorCauses"
      :total-size="totalSize"
      :page-size="pageSize"
      :page-number="pageNumber"
      :loading="isLoading"
      :features="features"
      :title="$t('error-causes.table-title')"
      @pageChanged="pageChanged"
      @itemCreated="onItemCreated"
      @itemUpdated="onItemUpdated"
      @itemDeleted="onItemDeleted"
      @sortingChanged="onSortingChanged"
      @refreshRequested="onRefreshRequested"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import ErrorCausesTable from '@/components/tables/ErrorCausesTable'

export default {
  components: { ErrorCausesTable },
  data () {
    return {
      errorCauses: [],
      isLoading: true,
      totalSize: 0,
      pageSize: 25,
      pageNumber: 1,
      latestSorting: undefined
    }
  },
  computed: {
    ...mapGetters('account', ['isOverlord']),
    features () {
      return this.isOverlord ? ['create', 'update', 'delete'] : ['create', 'update']
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('errorCauses', ['fetchErrorCauses']),
    onItemUpdated (updatedErrorCause) {
      // Try to find the updated error cause among the currently shown error causes.
      const index = this.errorCauses.findIndex((errorCause) => errorCause.id === updatedErrorCause.id)
      if (index !== -1) {
        // Update the error cause to the new values.
        // $set is a special method to allow vue to react to array changes.
        // See https://vuejs.org/v2/guide/reactivity.html for more info.
        this.$set(this.errorCauses, index, updatedErrorCause)
      }
    },
    async onItemCreated (newErrorCause) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onItemDeleted (deletedErrorCause) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onRefreshRequested () {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async pageChanged (newPageNumber) {
      // Update the current page number.
      this.pageNumber = newPageNumber
      // Fetch data with the new page number.
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onSortingChanged (newSorting) {
      this.latestSorting = newSorting
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    fetchPageDebounced: debounce(async function (pageNumber, pageSize, sorting) {
      await this.fetchPage(pageNumber, pageSize, sorting)
    }, 250),
    async fetchPage (pageNumber, pageSize, sorting) {
      this.isLoading = true
      try {
        // Fetch all errorCauses.
        const pagedResponse = await this.fetchErrorCauses({ pageNumber, pageSize, sorting })
        this.errorCauses = pagedResponse.items
        this.totalSize = pagedResponse.totalSize
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  created () {
    this.pageNumber = parseInt(this.$route.query.page || this.pageNumber)
  },
  async mounted () {
    await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
  },
  watch: {
    pageNumber (newVal, oldVal) {
      // Do nothing if the route is already correct.
      if (this.$route.query.page && parseInt(this.$route.query.page) === newVal) return
      // Replace the page number in the route/url to be the new page number.
      const query = { ...this.$route.query, page: newVal }
      this.$router.replace({ query })
    }
  }
}
</script>
