<template>
  <span>
    {{ displayText }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    projectIds: { type: Array, required: true }
  },
  computed: {
    ...mapGetters('projects', ['getProjectsByIds']),
    projects () {
      return this.getProjectsByIds(this.projectIds)
    },
    projectNames () {
      return this.projects.map(r => r.name)
    },
    displayText () {
      return this.projectNames.length > 0 ? this.projectNames.join(', ') : '-'
    }
  }
}
</script>
