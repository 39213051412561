<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card :loading="isLoading">
      <v-form>
        <!-- Title -->
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <!-- Content -->
        <v-card-text>
          <v-container>
            <v-row>
              <!-- Description -->
              <v-col cols="12">
                <v-text-field
                  v-model="description"
                  :label="$t('error-causes.dialog.description')"
                  :error-messages="validationErrors['description']"
                  :error-count="5"
                  autocomplete="off"
                />
              </v-col>

              <!-- Projects -->
              <v-col cols="12">
                <v-autocomplete
                  :label="$t('error-causes.dialog.project-label')"
                  :search-input.sync="projectSearchString"
                  @change="projectSearchString = ''"
                  v-model="selectedProjectIds"
                  :items="projects"
                  item-text="name"
                  item-value="id"
                  chips
                  deletable-chips
                  clearable
                  multiple
                  class="pa-0 ma-0"
                  :hint="$t('error-causes.dialog.project-hint')"
                  persistent-hint
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Buttons -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="close"
          >
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="save"
            :loading="isSaving"
            :disabled="isLoading"
          >
            {{ $t('common.buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true },
    errorCause: { type: Object, default: null }
  },
  emits: ['itemCreated', 'itemUpdated'],
  data () {
    return {
      description: '',
      selectedProjectIds: [],
      projectSearchString: '',
      isSaving: false,
      throttling: false,
      validationErrors: {},
      lastSuccessfulLoad: null,
      isLoading: false
    }
  },
  computed: {
    ...mapState('projects', ['projects']),
    isNew () {
      return this.errorCause == null
    },
    refetchImminent () {
      return !this.lastSuccessfulLoad || Date.now() - this.lastSuccessfulLoad > 60 * 1000
    },
    formTitle () {
      return this.$t(this.isNew ? 'error-causes.dialog.create-title' : 'error-causes.dialog.edit-title')
    },
    showDialog: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('projects', ['fetchAllProjects']),
    close () {
      this.showDialog = false
    },
    async save () {
      this.isSaving = true
      this.validationErrors = {}

      try {
        // Send a request to create/update the item.
        const response = this.isNew ? await this.createNew() : await this.updateExisiting()
        // Notify the dialog has saved the item.
        const event = this.isNew ? 'itemCreated' : 'itemUpdated'
        this.$emit(event, response.data)
        // Show a message indicating the save succeeded.
        this.showSnackbar({
          role: 'success',
          messages: [this.$t('common.save-success-message', { entityName: this.$t('common.error-cause') })],
          duration: 5000
        })
        // Close the dialog.
        this.close()
      } catch (error) {
        console.error(error)
        if (error.response.status === 400 && error.response && error.response.data && error.response.data.errors) {
          this.validationErrors = error.response.data.errors
        } else {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('common.save-failed-message', { entityName: this.$t('common.error-cause') })],
            duration: 5000
          })
        }
      } finally {
        this.isSaving = false
      }
    },
    async updateExisiting () {
      const payload = {
        id: this.errorCause.id,
        description: this.description,
        projectIds: this.selectedProjectIds
      }
      return await axios.put('errorcauses', payload)
    },
    async createNew () {
      const payload = {
        description: this.description,
        projectIds: this.selectedProjectIds
      }
      return await axios.post('errorcauses', payload)
    },
    async fetchData () {
      try {
        this.isLoading = true
        await this.fetchAllProjects()
        this.lastSuccessfulLoad = Date.now()
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
        this.close()
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    // Watch for value (v-model) changes. Occurs when the dialog is opened/closed.
    value: {
      immediate: true,
      async handler (newVal, oldVal) {
        // Skip update the values if the dialog is being opened.
        if (newVal) {
          this.description = this.errorCause?.description ? this.errorCause.description : ''
          this.selectedProjectIds = this.errorCause ? this.errorCause.projectIds : []
          this.validationErrors = {}

          if (this.refetchImminent) await this.fetchData()
        }
      }
    }
  }
}
</script>
